import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/terms-n-conditions',
      name: 'terms-n-conditions',
      component: () => import('@/views/TermsAndConditions.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Terms And Conditions Page',
        breadcrumb: [
          {
            text: 'Terms And Conditions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/PrivacyPolicy.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Privacy Policy Page',
        breadcrumb: [
          {
            text: 'Privacy Policy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/dapps/:name',
      name: 'details',
      component: () => import('@/views/DappDetail.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'Dapp Detail Page',
        breadcrumb: [
          {
            text: 'Dapp Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-dapp',
      name: 'add-dapp',
      component: () => import('@/views/AddDapp.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Add Dapp Page',
        breadcrumb: [
          {
            text: 'Add Dapp',
            active: true,
          },
        ],
      },
    },
    {
      path: '/all-dapp',
      name: 'all-dapp',
      component: () => import('@/views/AllDapps.vue'),
      meta: {
        requiresAuth: false,
        pageTitle: 'All Dapp Page',
        breadcrumb: [
          {
            text: 'All Dapp',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        requiresAuth: false,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

function is_authenticated() {
  const token = localStorage.getItem("token");
  if (token == "null" || token == null || token == "") {
    return false;
  } else {
    return true;
  }
}
/**
 * Router Authentication Guard
 */
router.beforeEach((to, from, next) => {
  const withoutAuth = ["login", "signup",  "register", "home", "terms-n-conditions", "privacy-policy", "details", "all-dapp"];
  if (withoutAuth.includes(to.name)) {
    next();
  }
  else if (!to.meta.requiresAuth) {
    is_authenticated() ? next(to.name) : next();
  } else {
    is_authenticated() ? next() : next({ name: "login" });
  }
});

export default router
