import API from '@/services/api';
import AXIOS from '@/services/api';

export default {
    state: {
        token: null,
        profile: null,
        user_data: []
    },
    getters: {

    },
    mutations: {
        SET_USER_DATA(state, user_data) {
            state.user_data = user_data;
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_PROFILE_DATA(state, profileData) {
            state.profile = profileData;
        },
    },
    actions: {
        FETCH_USER_DATA({ commit }, id) {
            return new Promise((reject) => {
                const token = localStorage.getItem('token');
                API.get(`coin/me/${id}`, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                }).then((response) => {
                    if (response) {
                        let payload = response.data.payload;
                        commit('SET_USER_DATA', payload);
                    } else {
                        reject();
                    }
                });
            });
        },
        REGISTER_USER({ commit }, userData) {
            return new Promise((resolve, reject) => {

                //set loader status to true
                commit('loaders/SET_LOADING', true, { root: true });

                AXIOS.post('authenticate/signup', userData).then((response) => {


                    let token = response.data.accessToken;
                    localStorage.setItem('token', token);

                    commit('SET_TOKEN', token);
                    resolve(response);

                }).catch((error) => {

                    reject(error);

                }).finally(() => {

                    //set loader status to false
                    commit('loaders/SET_LOADING', false, { root: true });
                });
            })
        },
        USER_LOGIN({ commit }, userData) {
            return new Promise((resolve, reject) => {

                //set loader status to true
                commit('loaders/SET_LOADING', true, { root: true });

                API.post('authenticate/login', userData).then((response) => {

                    let token = response.data.accessToken;
                    localStorage.setItem('token', token);
                    commit('SET_TOKEN', token);
                    // dispatch('FETCH_PROFILE_DATA', token)
                    resolve(response);

                }).catch((error) => {

                    reject(error);

                }).finally(() => {
                    //set loader status to false
                    commit('loaders/SET_LOADING', false, { root: true });
                });
            })
        },
        async FETCH_PROFILE_DATA({ commit }, token) {
            return new Promise((resolve, reject) => {

                //set loader status to true
                commit('loaders/SET_LOADING', true, { root: true });

                // let headers = {
                //     'Authorization': 'Bearer ' + token
                // };

                API.get('authenticate/profile/me', { token }).then((response) => {

                    commit('SET_PROFILE_DATA', response.data);
                    localStorage.setItem('profile', JSON.stringify(response.data));
                    resolve(response);

                }).catch((error) => {

                    reject(error);

                }).finally(() => {
                    //set loader status to false
                    commit('loaders/SET_LOADING', false, { root: true });
                });
            })
        },
    }
}