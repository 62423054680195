import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import numeral from 'numeral'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import i18n from '@/libs/i18n'
import vGoogleTranslate from 'v-google-translate';
// import style
import 'swiper/css/swiper.css'
 
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_WEBSOCKET_KEY,
//   wsHost: process.env.VUE_APP_WEBSOCKET_HOST,
//   wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
//   wssPort: process.env.VUE_APP_WEBSOCKET_PORT,
//   forceTLS: false,
//   disableStats: true
// })

Vue.use(vGoogleTranslate)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
