import axios from 'axios';
import { getAuthToken } from "./tokens";

let api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "x-api-key": process.env.VUE_APP_X_KEY,
    "x-api-secret": process.env.VUE_APP_X_SECRET,
    "Accept-Language": "en-US",
    "Content-Type": "application/json",
    Authorization: "Bearer " + getAuthToken()
  }
});

export default api;

export const AXIOS = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 50000,
  headers: {
    "x-api-key": process.env.VUE_APP_X_KEY,
    "x-api-secret": process.env.VUE_APP_X_SECRET,
    "Accept-Language": "en-US",
    "Content-Type": "application/json",
    Authorization: "Bearer " + getAuthToken()
  }
});