import API from '@/services/api';
// import AXIOS from '@/services/api';

export default {
  state: {
    dappData: [],
    all_total: null,
    singleDappData: [],
    clientIP: null,
  },
  getters: {

  },
  mutations: {
    SET_CLIENT_IP(state, clientIP) {
      state.clientIP = clientIP;
    },
    SET_DAPP_DATA(state, dappData) {
      state.dappData = dappData;
    },
    SET_SINGLE_DAPP_DATA(state, singleDappData) {
      state.singleDappData = singleDappData;
    },
    DAPP_TOTAL(state, totalData) {
      state.all_total = totalData;
    },
  },
  actions: {
    FETCH_DAPP_DATA({ commit }, query) {
      return new Promise((resolve, reject) => {
        commit('loaders/SET_LOADING', true, { root: true })
        API.get("dapp" + query).then((response) => {
          if (response) {
            let payload = response.data.data;
            commit('SET_DAPP_DATA', payload);
            commit('loaders/SET_LOADING', false, { root: true })
            commit('DAPP_TOTAL', response.data.data.length)
            resolve();
          } else {
            commit('loaders/SET_LOADING', false, { root: true })
            reject();
          }
        });
      });
    },
    FETCH_CLIENT_IP({ commit }) {
      return new Promise((resolve, reject) => {
        commit('loaders/SET_API_LOADING', true, { root: true })
        fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(({ ip }) => {
            if (ip) {
              let clientIP = ip;
              commit('SET_CLIENT_IP', clientIP);
              //set client IP into local storage
              localStorage.setItem("clientIP", clientIP);
              resolve(clientIP);
              commit('loaders/SET_API_LOADING', false, { root: true })
            }

            else {
              reject(
                commit('loaders/SET_API_LOADING', false, { root: true })
              );
            }
          });
      });
    },
    CAST_VOTE({ dispatch, commit }, data) {

      return new Promise((resolve, reject) => {
        commit('loaders/SET_LOADING', true, { root: true })
        //get the ip address from store
        let clientIP = localStorage.getItem("clientIP");
        let user_id = localStorage.getItem("client_ID");

        if (clientIP != null) {
          //send the vote request
          API.post(`vote`, { ip_address: clientIP, dapp_id: data.id, user_id: user_id }).then((response) => {
            commit('loaders/SET_LOADING', false, { root: true })

            dispatch('FETCH_DAPP_DATA', data.query);

            resolve(response);

          }).catch((error) => {

            reject(error);
          })
            .finally(() => {
              commit('loaders/SET_LOADING', false, { root: true })
            });
        }
        else {
          commit('loaders/SET_LOADING', false, { root: true })
        }

      })

    },
    FETCH_SINGLE_DAPP_DATA({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('loaders/SET_LOADING', true, { root: true })
        API.get("dapp/" + id).then((response) => {
          if (response) {
            let payload = response.data.data;
            commit('SET_SINGLE_DAPP_DATA', payload);
            commit('loaders/SET_LOADING', false, { root: true })
            resolve();
          } else {
            commit('loaders/SET_LOADING', false, { root: true })
            reject();
          }
        });
      });
    },
  }
}